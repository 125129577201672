<template>
  <client-only>
    <div v-if="galleryId" :data-gallery-id="galleryId" class="yotpo yotpo-pictures-widget" />
  </client-only>
</template>

<script setup lang="ts">
useYotpoRefresh();

defineProps({
  galleryId: {
    type: String,
    required: true,
  },
});
</script>
